import React from "react"
import ContactUs from "../components/contactUs"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ThemeProvider from "@material-ui/styles/ThemeProvider"
import theme from "../theme"

const contactUsPage = () => (
  <React.Fragment>
    <ThemeProvider theme={theme}>
      <Layout>
        <SEO title="Contact Us"/>
        <ContactUs />
      </Layout>
    </ThemeProvider>
  </React.Fragment>
)

export default contactUsPage
